<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("PlaceList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-map-pin</v-icon>

      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1" href="#tab-list">{{
            $t("PlaceList.tabList")
          }}</v-tab>
          <v-tab key="tab2" href="#tab-map">{{ $t("PlaceList.tabMap") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <!-- U tab2/Mapa zablokovať touchless, lebo sa neda normalne pohybovat na mape -->
    <v-tabs-items v-model="tabs" :touchless="tabs == 1">
      <!-- TAB1 Zoznam places-->
      <v-tab-item value="tab-list" eager>
        <v-btn
          small
          class="my-2"
          color="success"
          @click.stop="openCreateDialog()"
        >
          {{ $t("btnCreate") }}
        </v-btn>
        <v-data-table
          :loading="loadingData"
          :headers="gridHeaders"
          :items="gridItems"
          sort-by="displayName"
          class="elevation-1"
          :items-per-page="50"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100, 250, 500, 1000, 5000 - 1],
            showFirstLastPage: true,
          }"
        >
          <template v-slot:[`item.active`]="{ item }">
            <v-simple-checkbox
              v-model="item.active"
              disabled
            ></v-simple-checkbox>
          </template>

          <template v-slot:no-data>
            <v-btn color="error" @click="getAll(false)">
              {{ $t("btnReload") }}</v-btn
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="ml-2" @click="openEditDialog(item)">
              mdi-square-edit-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- TAB2 Map places-->
      <v-tab-item value="tab-map" eager>
        <GoogleMap
          :passSelectedAddressOnMap="passAddressToMap"
          :passNearbyAddressesOnMap="passNearbyAddressesToMap"
          :passSelectedGoogleAddressOnMap="searchNewGoogleModelToMap"
          :passSelectedGeocodeAddressOnMap="searchNewGeocodeModelToMap"
          @actualMapBounds="actionForActualMapBounds($event)"
          @addressDragged="fnAddressDragged($event)"
        />
        <!-- Panel with position: top center of the page -->
        <div
          style="
            z-index: 1;
            position: absolute;
            left: 50%;
            top: 0;
            margin-top: 10px;
            margin-left: -220px;
          "
        >
          <!-- Panel na vytvorenie novej adresy do databazy -->
          <v-card
            :max-width="$vuetify.breakpoint.smAndDown ? '' : '440'"
            class="mx-auto"
            :width="showAddressNewPanel ? '320' : ''"
          >
            <v-toolbar color="secondary" dense dark>
              <v-app-bar-nav-icon
                :label="$t('PlaceList.toolbar_icon_title')"
                @click.stop="showAddressNewPanel = !showAddressNewPanel"
              >
                <v-icon v-if="showAddressNewPanel">mdi-menu-down</v-icon>
                <v-icon v-else>mdi-menu-up</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title>{{
                $t("PlaceList.toolbarNew_title")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <!-- refresh list button with new only -->
              <v-btn
                icon
                @click="
                  getAll(true);
                  showAddressListPanel = true;
                "
                v-show="showAddressNewPanel"
                :title="$t('btnReload')"
                class="mx-0"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-toolbar>
            <v-form ref="formCreate">
              <v-container
                style="height: 125px; max-height: 30vh"
                class="overflow-y-auto pa-1"
                v-show="showAddressNewPanel"
              >
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <autocomplete-address-remote
                        v-model="searchNewGoogleModel"
                        :label="$t('PlaceList.searchNewGoogle')"
                        prepend-icon="fa-map-pin"
                        :searchParams="addressSearchData"
                        :mapBounds="addressMapBounds"
                        ref="searchNewGoogle"
                        prependIconColor="info"
                        :hideDetails="true"
                      >
                      </autocomplete-address-remote>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <autocomplete-address-remote-geocode
                        v-model="searchNewGeocodeModel"
                        :label="$t('PlaceList.searchNewGeocode')"
                        prepend-icon="fa-map-pin"
                        :searchParams="addressSearchData"
                        :mapBounds="addressMapBounds"
                        ref="searchNewGoogle"
                        prependIconColor="warning"
                        :hideDetails="true"
                      >
                      </autocomplete-address-remote-geocode>
                    </v-col> </v-row
                ></v-card-text>
              </v-container>
            </v-form>
          </v-card>
        </div>
        <div
          style="z-index: 1; position: absolute; left: 0; top: 0; margin: 10px"
        >
          <v-card max-width="390" class="mx-auto">
            <v-toolbar color="secondary" dense dark>
              <v-app-bar-nav-icon
                :label="$t('PlaceList.toolbar_icon_title')"
                @click.stop="showAddressListPanel = !showAddressListPanel"
              >
                <v-icon v-if="showAddressListPanel">mdi-menu-down</v-icon>
                <v-icon v-else>mdi-menu-up</v-icon>
              </v-app-bar-nav-icon>
              <!-- Zobrazit nazov panela, iba pri minimalizovani. Ak je otvoreny, je jasne co tam je -->
              <v-toolbar-title v-show="!showAddressListPanel">{{
                $t("PlaceList.toolbarList_title")
              }}</v-toolbar-title>
              <!-- Vyhľadávacie pole je potrebné potvrdzovať Enter -->
              <v-text-field
                class="mx-1"
                solo-inverted
                dense
                hide-details
                :label="$t('PlaceList.searchField')"
                prepend-inner-icon="mdi-magnify"
                :value="search"
                @change="(v) => (search = v)"
                clearable
                @click:clear="
                  clearSearch();
                  search = '';
                "
                v-show="showAddressListPanel"
                :title="$t('PlaceList.searchField_title')"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-switch
                v-show="showAddressListPanel"
                dense
                :title="
                  $t('PlaceList.showOnlyNotApprovedAddressesSwitch_title')
                "
                hide-details
                v-model="showOnlyNotApprovedAddresses"
                @change="switchOnlyNotApprovedAddresses()"
              ></v-switch>
              <!-- refresh list button -->
              <v-btn
                icon
                @click="getAll(false)"
                v-show="showAddressListPanel"
                :title="$t('btnReload')"
                class="mx-0"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-progress-linear
                absolute
                bottom
                :indeterminate="loadingIndeterminate"
                :buffer-value="loading ? 100 : 0"
                :value="loading ? 100 : 0"
              ></v-progress-linear>
              <!--:style="{visibility: loading ? 'visible' : 'hidden'}"-->
            </v-toolbar>
            <!-- <div class="overflow-y-auto" style="max-height: 80vh" v-show="showAddressListPanel"> -->
            <!-- gridItems -->
            <div v-show="showAddressListPanel" class="grey">
              <v-virtual-scroll
                :items="orderedItems"
                bench="10"
                :item-height="120"
                height="65vh"
                ><template v-slot:default="{ item }">
                  <AddressListItem
                    :source="item"
                    itemHeight="118"
                    :key="item.id"
                    @showAddressOnMap="fnShowAddressOnMap($event)"
                  />
                </template>
              </v-virtual-scroll>
            </div>
          </v-card>
        </div>
        <div
          style="z-index: 2; position: absolute; right: 0; top: 0; margin: 10px"
        >
          <v-card
            :max-width="$vuetify.breakpoint.smAndDown ? '' : '510'"
            class="mx-auto"
          >
            <v-toolbar color="secondary" dense dark>
              <v-app-bar-nav-icon
                :label="$t('PlaceList.toolbar_icon_title')"
                @click.stop="showAddressDetailPanel = !showAddressDetailPanel"
              >
                <v-icon v-if="showAddressDetailPanel">mdi-menu-down</v-icon>
                <v-icon v-else>mdi-menu-up</v-icon>
              </v-app-bar-nav-icon>
              <v-toolbar-title>{{
                $t("PlaceList.toolbarDetails_title")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-form ref="formCreate">
              <v-container
                style="height: 450px; max-height: 85vh"
                class="overflow-y-auto pa-1"
                v-show="showAddressDetailPanel"
              >
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.displayName')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.displayName"
                        :rules="[(v) => !!v || $t('msgRequiredField')]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-checkbox
                        v-model="editItem.active"
                        :label="$t('PlaceList.active')"
                        required
                        filled
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.latitude')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.latitude"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.longitude')"
                        filled
                        dense
                        hide-details
                        type="number"
                        v-model="editItem.longitude"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.houseNumber')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.houseNumber"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.road')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.road"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.city')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.state')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.state"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.postcode')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.postcode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        :label="$t('PlaceList.country')"
                        filled
                        dense
                        hide-details
                        v-model="editItem.countrySelector"
                        :items="$t('CountryCodeShortList')"
                        :change="onChangeCountry()"
                      ></v-select
                    ></v-col>
                    <v-col v-show="false" cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.country')"
                        filled
                        dense
                        disabled
                        hide-details
                        v-model="editItem.country"
                      ></v-text-field>
                    </v-col>
                    <v-col v-show="false" cols="12" md="6">
                      <v-text-field
                        :label="$t('PlaceList.countryCode')"
                        filled
                        dense
                        disabled
                        hide-details
                        v-model="editItem.countryCode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-expansion-panels class="pt-1">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t("PlaceList.more") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.amenityName')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.amenityName"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.amenityType')"
                              filled
                              dense
                              disabled
                              hide-details
                              v-model="editItem.amenityType"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.neighbourhood')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.neighbourhood"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.suburb')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.suburb"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.cityDistrict')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.cityDistrict"
                            ></v-text-field> </v-col
                          ><v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.county')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.county"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.ordering')"
                              filled
                              dense
                              hide-details
                              v-model="editItem.ordering"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="$t('PlaceList.priority')"
                              filled
                              dense
                              hide-details
                              type="number"
                              v-model="editItem.priority"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    :loading="loadingSaveItem"
                    text
                    @click.stop="submitEditDialog()"
                    >{{ $t("btnSave") }}</v-btn
                  >
                </v-card-actions>
              </v-container>
            </v-form>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{ $t("PlaceList.dialogTitle") }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.displayName')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.displayName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="editItem.active"
                    :label="$t('PlaceList.active')"
                    required
                    filled
                    dense
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.latitude')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.latitude"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.longitude')"
                    filled
                    dense
                    hide-details
                    type="number"
                    v-model="editItem.longitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.amenityName')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.amenityName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.amenityType')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.amenityType"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.houseNumber')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.houseNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.road')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.road"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.neighbourhood')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.neighbourhood"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.suburb')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.suburb"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.cityDistrict')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.cityDistrict"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.city')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.county')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.county"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.state')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.state"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.postcode')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.postcode"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('PlaceList.country')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.countrySelector"
                    :items="$t('CountryCodeShortList')"
                    :change="onChangeCountry()"
                  ></v-select
                ></v-col>
                <v-col v-show="false" cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.country')"
                    filled
                    dense
                    hide-details
                    disabled
                    v-model="editItem.country"
                  ></v-text-field>
                </v-col>
                <v-col v-show="false" cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.countryCode')"
                    filled
                    dense
                    hide-details
                    disabled
                    v-model="editItem.countryCode"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.ordering')"
                    filled
                    dense
                    hide-details
                    v-model="editItem.ordering"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('PlaceList.priority')"
                    filled
                    dense
                    hide-details
                    type="number"
                    v-model="editItem.priority"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn
              color="success"
              :loading="loadingSaveItem"
              text
              @click.stop="submitEditDialog()"
              >{{ $t("btnSave") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import GoogleMap from "../components/PlaceGoogleMap.vue";
import AddressListItem from "../components/PlaceAddressListItem.vue";
import AutocompleteAddressRemote from "../components/AutocompleteAddressRemote.vue";
import AutocompleteAddressRemoteGeocode from "../components/AutocompleteAddressRemoteGeocode.vue";

export default {
  name: "App",
  components: {
    GoogleMap,
    AddressListItem,
    AutocompleteAddressRemote,
    AutocompleteAddressRemoteGeocode,
  },

  data() {
    return {
      tabs: null,
      showAddressListPanel: true,
      showAddressDetailPanel: true,
      showAddressNewPanel: false,
      //pre virtual scroller
      //item: AddressListItem,
      showOnlyNotApprovedAddresses: true,
      posts: [],
      errors: [],
      search: "",
      refreshTimer: null,
      loading: false,
      loadingIndeterminate: false,

      gridHeaders: [
        {
          text: this.$t("PlaceList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        { text: this.$t("PlaceList.tableHeaders.active"), value: "active" },
        {
          text: this.$t("PlaceList.tableHeaders.displayName"),
          value: "displayName",
        },
        { text: this.$t("PlaceList.tableHeaders.city"), value: "city" },
        {
          text: this.$t("PlaceList.tableHeaders.countryCode"),
          value: "countryCode",
        },
        {
          text: this.$t("PlaceList.tableHeaders.categoryName"),
          value: "categoryName",
        },
        { text: this.$t("PlaceList.tableHeaders.ordering"), value: "ordering" },
        { text: this.$t("PlaceList.tableHeaders.priority"), value: "priority" },
      ],
      gridItems: [],
      lastFullGridItems: [],

      loadingData: false,
      loadingSaveItem: false,
      //Add new address
      searchNewGoogleModelToMap: null,
      searchNewGoogleModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      searchNewGeocodeModelToMap: null,
      searchNewGeocodeModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      addressMapBounds: null,
      addressSearchData: {
        query: null,
        countryCode: null,
        city: null,
        taxiserviceId: null,
      },

      editDefaultItem: {
        id: null,
        active: true,
        latitude: null,
        longitude: null,
        amenityType: null,
        amenityName: null,
        houseNumber: null,
        road: null,
        neighbourhood: null,
        suburb: null,
        cityDistrict: null,
        city: null,
        county: null,
        state: null,
        postcode: null,
        country: "SK",
        countryCode: null,
        displayName: null,
        ordering: 0,
        priority: 0,
        categoryId: null,
        categoryName: null,
        countrySelector: "SK",
      },
      editItem: {},
      editDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
      //Odovzdanie adresy zo zoznamu pre mapu
      passAddressToMap: null,
      passNearbyAddressesToMap: null,

      //request data pre zistenie suvisiacich adries
      searchAddress: {
        address: "",
      },
      //mockovane data, budu nahradene api
      mockNearbyAddressesOne: {
        createdAt: "2020-01-01",
        driverId: 4,
        latitude: 48.5871178,
        longitude: 19.0976246,
        orderId: "aaaaaa-bbbbbbb-ccccccc-dddddd",
        speed: 1,
      },
      mockNearbyAddresses: [
        {
          createdAt: "2020-01-01",
          driverId: 1,
          latitude: 48.5871178,
          longitude: 19.0976246,
          orderId: "aaaaaa-bbbbbbb-ccccccc-dddddd",
          speed: 1,
        },
        {
          createdAt: "2021-02-02",
          driverId: 2,
          latitude: 48.298,
          longitude: 19.468,
          orderId: "xxxxxxx-bbbbbbb-ccccccc-dddddd",
          speed: 1,
        },
        {
          createdAt: "2022-03-03",
          driverId: 3,
          latitude: 48.398,
          longitude: 19.498,
          orderId: "wwwwwww-bbbbbbb-ccccccc-dddddd",
          speed: 1,
        },
      ],
    };
  },

  created() {},

  mounted() {
    this.getAll(false);
  },
  computed: {
    orderedItems: function () {
      //pri uvodnom nacitani objektu nie su data, teda nepozit funkciu, kym nebudu data
      if (!this.gridItems) {
        return [];
      }
      return this.gridItems.filter((item) => {
        //clearable u pola nastavuje hodnotu search na null, co v tejto funkcii vyhadzuje chybu, treba mat ""
        if (!this.search) {
          this.search = "";
        }
        //console.log("filter - search: ", this.search);
        //console.log("filter - item.id: ", item.id);
        return (
          //item.id.toLowerCase().match(this.search) ||
          (item.displayName
            ? item.displayName
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.amenityName
            ? item.amenityName
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.houseNumber
            ? item.houseNumber
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.road
            ? item.road
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.city
            ? item.city
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.formattedAddress
            ? item.formattedAddress
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false) ||
          (item.postcode
            ? item.postcode
                .toLocaleLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  this.search
                    .toLocaleLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            : false)
          //||  item.createdAt.toLowerCase().match(this.search)
        );
      });
    },
  },
  watch: {
    searchNewGoogleModel: function (val) {
      this.searchNewGoogleModelToMap = Object.assign({}, val);
      console.log("searchNewGoogleModelToMap", this.searchNewGoogleModelToMap);
    },
    searchNewGeocodeModel: function (val) {
      this.searchNewGeocodeModelToMap = Object.assign({}, val);
    },
  },
  methods: {
    getAll(newonly = false) {
      this.loadingData = true;
      this.newonly = newonly;
      var lastFullGridItems = this.lastFullGridItems;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place`)
        .then((response) => {
          this.loadingData = false;
          //ak this.gridItems nie je prazdne, tak do this.gridItems nacitat iba nove zaznamy
          if (this.gridItems && newonly) {
            this.gridItems = response.data.filter((item) => {
              return !lastFullGridItems.some((lastItem) => {
                return lastItem.id === item.id;
              });
            });
          } else {
            this.gridItems = this.lastFullGridItems = response.data;
          }
          //ak this.gridItems
          //this.gridItemsClone = JSON.parse(JSON.stringify(this.gridItems));
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    actionForActualMapBounds(boundsObject) {
      this.addressMapBounds = boundsObject;
      console.log("boundsObject", boundsObject);
    },
    openCreateDialog() {
      this.editItem = Object.assign({}, this.editDefaultItem);
      this.editDialog = true;
      if (this.$refs.formCreate) {
        this.$refs.formCreate.resetValidation();
      }
    },
    openEditDialog(item) {
      this.editItem = Object.assign({}, item);
      this.editItem.countrySelector = item.countryCode;
      this.editDialog = true;
      this.fnShowAddressOnMap(item);
    },
    onChangeCountry() {
      var countryCode = this.editItem.countrySelector;
      //console.log("countryCode", countryCode);
      var countryCodeList = this.$t("CountryCodeShortList");
      var countryText = "";
      var countryValue = "";
      countryCodeList.forEach(function (item, index) {
        if (item.value == countryCode) {
          countryText = item.text;
          countryValue = item.value;
        }
      });
      this.editItem.country = countryText;
      this.editItem.countryCode = countryValue;
    },

    submitEditDialog() {
      const isValid = this.$refs.formCreate.validate();
      //var countryCodeList ="CountryCodeShortList." + this.editItem.countryCode;
      //this.editItem.country = this.$t('CountryCodeShortList.SK');
      //console.log("editItem", this.editItem);
      if (isValid) {
        this.loadingSaveItem = true;
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
            this.editItem
          )
          .then((response) => {
            this.editDialog = false;

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";

            this.loadingSaveItem = false;
            this.getAll(this.newonly);
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";

            this.loadingSaveItem = false;
          });
      }
    },
    clearSearch() {
      this.search = "";
      //console.log("clearSearch function: ", this.search);
    },
    fnShowAddressOnMap(item) {
      console.log("PlaceList.vue - fnShowAddressOnMap: ", item);
      this.passAddressToMap = item;
      //Zobrazenie v editacnom poli adresy na mape
      this.editItem = Object.assign({}, item);
      this.editItem.countrySelector = item.countryCode;
      this.searchAddress.address = this.editItem.displayName;
      var tempNearbyAddress = [];
      console.log("searchAddress", this.searchAddress);
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/nearby`, {
          params: this.searchAddress,
        })
        .then((response) => {
          tempNearbyAddress = response.data;
          console.log(
            "GET request Nearby- tempNearbyAddress: ",
            tempNearbyAddress
          );

          //PRIDAME BODOVANIE PODLA TOHO AKO SU VZDALENIE OD ADRESY
          //find simple distance between this.editItem and every tempNearbyAddress. Order by distance. Do not use geolib library

          if (tempNearbyAddress.length > 0) {
            console.log("tempNearbyAddress.length > 0");
            tempNearbyAddress.forEach(
              function (item, index) {
                var distance = Math.sqrt(
                  Math.pow(item.latitude - this.editItem.latitude, 2) +
                    Math.pow(item.longitude - this.editItem.longitude, 2)
                );
                //round distance to 4 decimal places
                distance = Math.round(distance * 1000) / 1000;
                item.distance = distance;
              }.bind(this)
            );
            //sort by distance desc
            tempNearbyAddress.sort(function (a, b) {
              return b.distance - a.distance;
            });
            //Add scoring to tempNearbyAddress. 10 points for each order in tempNearbyAddress.
            //Cim blizsie k adrese, tak vacsie je score
            var score = 0;
            tempNearbyAddress.forEach(function (item, index) {
              score = score + 10;
              item.score = score;
              //console.log("score - distance: ", score);
              //console.log("item.driverId: ", item.driverId);
            });
            //Cim vyssia rychlost pri zaznamenani polohy, tym nizsie skore
            //hranica pre relevantny udaj je 1km/h
            var tempScore = 0;
            tempNearbyAddress.forEach(function (item, index) {
              tempScore = item.score;
              tempScore = tempScore * (1.1 - (item.speed > 1 ? 1 : item.speed));
              //round tempScore to 0 decimal places
              item.score = Math.round(tempScore);
              //console.log("score - speed: ", item.score + " - " + item.speed);
            });

            //reorder tempNearbyAddress by score desc
            tempNearbyAddress.sort(function (a, b) {
              return b.score - a.score;
            });
            //Vyberieme najrelevantnejsie adresa (najvacsie score, resp. najblizsie polohy k adrese)
            //select positions whose score is greater than 0.6 * maxScore
            var maxScore = tempNearbyAddress[0].score;
            console.log("maxScore", maxScore);
            var tempNearbyAddressSelected = [];
            tempNearbyAddress.forEach(function (item, index) {
              if (item.score > 0.6 * maxScore) {
                tempNearbyAddressSelected.push(item);
              }
            });
            //find central position between all tempNearbyAddressSelected which has score more than 60% of max. Higher score is more central.
            var centralPosition = {
              latitude: 0,
              longitude: 0,
            };
            var centralPositionScore = 0;
            tempNearbyAddressSelected.forEach(function (item, index) {
              centralPosition.latitude =
                centralPosition.latitude + item.latitude * item.score;
              centralPosition.longitude =
                centralPosition.longitude + item.longitude * item.score;
              centralPositionScore = centralPositionScore + item.score;
            });
            centralPosition.latitude =
              centralPosition.latitude / centralPositionScore;
            centralPosition.longitude =
              centralPosition.longitude / centralPositionScore;
            //add central position to tempNearbyAddress
            var tempNearbyAddressCentral = Object.assign(
              {},
              tempNearbyAddress[0]
            );
            tempNearbyAddressCentral.latitude = centralPosition.latitude;
            tempNearbyAddressCentral.longitude = centralPosition.longitude;
            tempNearbyAddressCentral.distance = 0;
            tempNearbyAddressCentral.score = 0;
            tempNearbyAddressCentral.driverId = 0;
            tempNearbyAddressCentral.speed = 0;
            //createdAt now iso string
            tempNearbyAddressCentral.createdAt = new Date().toISOString();
            tempNearbyAddressCentral.orderId = "";
            //add central position to tempNearbyAddress at the beginning (index 0)
            tempNearbyAddress.unshift(tempNearbyAddressCentral);

            this.passNearbyAddressesToMap = tempNearbyAddress;
          } else {
            console.log("tempNearbyAddress.length <= 0");
            this.passNearbyAddressesToMap = [];
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgResponseError");
          this.snackbar.color = "error";
        });
    },
    fnAddressDragged(item) {
      console.log("PlaceList.vue - fnAddressDragged: ", item);
      this.editItem.latitude = item.latitude;
      this.editItem.longitude = item.longitude;
    },
    switchOnlyNotApprovedAddresses() {},
  },
};
</script>
